import * as React from "react";
import * as styles from "./about-us.module.css";

import { FunctionComponent } from "react";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const AboutUs: FunctionComponent = () => {
  return (
    <Layout>
    <div className={styles.aboutUsDiv}>
      <div className={styles.aboutUsIntroDiv}>
        <div className={styles.aboutUsDiv1}>
          <div className={styles.aboutUsTextDiv}>
            <div className={styles.titleDiv}>
              <div className={styles.displayDiv}>About Us</div>
            </div>
            <div className={styles.heroTextDiv}>
              <div className={styles.pDiv}>
                <div className={styles.captionDiv}>
                  Continuing a business in the healthcare industry is complex.
                  With new and upgraded clinical and medical tools introduced
                  yearly, you may find it tough to identify the best products
                  for your business.
                </div>
              </div>
              <div className={styles.pDiv}>
                <div className={styles.captionDiv}>
                  At Global Care Supply, we help you with affordable
                  consultation and solutions to upgrade or start your business
                  in the healthcare industry. We offer affordable clinical
                  instruments and medical equipment at wholesale prices under
                  the supervision of industry experts with over 38 years of
                  combined experience.
                </div>
              </div>
            </div>
          </div>
          <img className={styles.imageIcon} alt="" src="../image6@2x.png" />
        </div>
      </div>
      <div className={styles.keyValuesDiv}>
        <div className={styles.aboutUsSectionDiv}>
          <img className={styles.patternsIcon} alt="" src="../patterns2.svg" />
          <div className={styles.aboutUsFeaturesDiv}>
            <div className={styles.titleDiv}>
              <b className={styles.label}>What we offer:</b>
            </div>
            <div className={styles.grid2x2Div}>
              <div className={styles.row1Div}>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled4.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv1}>Affordable products</div>
                  </div>
                </div>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled4.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv1}>Free consultation</div>
                  </div>
                </div>
              </div>
              <div className={styles.row1Div}>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled6.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv1}>Customer support</div>
                  </div>
                </div>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled7.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv1}>Global shipping</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.aboutUsFeaturesDiv1}>
            <div className={styles.cardLabelDiv1}>
              <b className={styles.label}>Why we stand out:</b>
            </div>
            <div className={styles.grid2x2Div1}>
              <div className={styles.row1Div1}>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled4.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv1}>
                      We offer a great balance of quality and affordability
                    </div>
                  </div>
                </div>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled4.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv1}>
                      Our responsive customer care team
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.row1Div1}>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled6.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv1}>
                      Fast and affordable global shipping policies
                    </div>
                  </div>
                </div>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled7.svg"
                  />
                  <div className={styles.textDiv}>
                      <div className={styles.titleDiv1}>
                         Range for products for commercial and private practice
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.pDiv}>
              <div className={styles.row1Div2}>
                <div className={styles.featureDiv}>
                  <img
                    className={styles.iconFilled}
                    alt=""
                    src="../icon--filled12.svg"
                  />
                  <div className={styles.textDiv}>
                    <div className={styles.titleDiv1}>
                      Products from leading brands
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Layout>
  );
};

export default AboutUs;

export const Head = () => (
  <SEO title="Global Care Supply" description="About Us" />
)
